import React from "react";

const NotFound = () => (
    <>
    <h2>Page not found :-(</h2>
    <p>Hmm... I'm not sure what page you're looking for</p>
    </>
)
    

export default(NotFound);